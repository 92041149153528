import fetchJson from "@/lib/fetchJson";
import { Avatar, Button, Flex, Heading, Link, Table } from "@radix-ui/themes";
import { IconBrandTiktok, IconBrandX, IconRefresh } from "@tabler/icons-react";
import { IconBrandInstagram } from "@tabler/icons-react";
import { IconBrandYoutube } from "@tabler/icons-react";

export default function TrackingProgress({
  campaign,
  mutate,
}: {
  campaign: Campaign;
  mutate: () => void;
}) {
  console.log(campaign);
  const getIcon = (budget) => {
    if (budget.creatorPrice.platform === "tiktok") {
      return <IconBrandTiktok className="icon" />;
    }
    if (budget.creatorPrice.platform === "instagram") {
      return <IconBrandInstagram className="icon" />;
    }
    if (budget.creatorPrice.platform === "twitter") {
      return <IconBrandX className="icon" />;
    }
    if (budget.creatorPrice.platform === "youtube") {
      return <IconBrandYoutube className="icon" />;
    }
  };

  const getLocalTime = (date) => {
    if (!date) return "-";
    return new Date(date).toLocaleString();
  };

  const scrapePrice = async (id: number) => {
    await fetchJson("/creator/price/" + id + "/scrape", {
      method: "POST",
    });
    alert("Scraping in progress, please wait 5 minutes before checking again");
    mutate();
  };
  return (
    <>
      <Heading size="4" style={{ marginTop: 15, paddingLeft: 10 }}>
        Tracking Progress
      </Heading>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Creator</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Platform</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Last Attempted</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Last Successful</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaign.budgets.map((budget: any) => (
            <Table.Row key={budget.creatorPriceId}>
              <Table.Cell>
                <Flex direction="row" align="center" gap="2" overflowX="scroll">
                  <Avatar
                    src={`/image-upload/${budget.creatorPrice.avatar}`}
                    fallback={
                      Array.from(budget.creatorPrice.creator.name)[0] as string
                    }
                    radius="full"
                  />
                  <Link href={`/creators/${budget.creatorPrice.creatorId}`}>
                    {budget.creatorPrice.username}
                  </Link>
                </Flex>
              </Table.Cell>
              <Table.Cell>{getIcon(budget)}</Table.Cell>
              <Table.Cell>
                {getLocalTime(budget.creatorPrice.lastAttempted)}
              </Table.Cell>
              <Table.Cell>
                {getLocalTime(budget.creatorPrice.lastScraped)}
              </Table.Cell>
              <Table.Cell>
                <Button
                  variant="outline"
                  onClick={() => scrapePrice(budget.creatorPrice.id)}
                >
                  <IconRefresh className="icon" />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </>
  );
}
